import React from 'react';
import { LinearProgress } from '@material-ui/core';

export const Loader = () => {
    return (
        <LinearProgress
            color="secondary"
            variant="indeterminate"
        />
    );
}