import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useClasses = makeStyles((theme) => ({
    paper: {
        margin: 10,
        padding: 10
    },
    typography: {
        marginBottom: '5px'
    },
    input: {
        margin: '5px 0'
    },
    button: {
        marginTop: '5px'
    }
}));

const Login = ({
    emailFieldName,
    passwordFieldName,
    confirmPasswordFieldName,
    changeHandler,
    registerHandler
}) => {

    const classes = useClasses();

    return (
        <Paper elevation={10} className={classes.paper}>

            <Typography variant="h5" className={classes.typography}>Register</Typography>

            <TextField
                id={`reg-${emailFieldName}`}
                name={emailFieldName}
                label="Email"
                placeholder="Please enter email"
                type="email"
                fullWidth
                required
                onChange={changeHandler}
                className={classes.input}
            />
            <TextField
                id={passwordFieldName}
                name={passwordFieldName}
                label="Password"
                placeholder="Please enter password"
                type="password"
                fullWidth
                required
                onChange={changeHandler}
                className={classes.input}
            />
            <TextField
                id={confirmPasswordFieldName}
                name={confirmPasswordFieldName}
                label="Confirm Password"
                placeholder="Please confirm password"
                type="password"
                fullWidth
                required
                onChange={changeHandler}
                className={classes.input}
            />
            <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                onClick={registerHandler}
                className={classes.button}
            >Register</Button>

        </Paper>
    )
}

export default Login;