import React from 'react';

const AuthTabPanel = ({ tabValue, tabIndex, children }) => {

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== tabIndex}
      id={`auth-tabpanel-${tabIndex}`}
    >
      {children}
    </div>
  );
}

export default AuthTabPanel;