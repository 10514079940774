import { React, useState, useEffect, useContext } from 'react';
import { Grid, Paper, Tabs, Tab, Typography } from '@material-ui/core';
import AuthTabPanel from '../components/Auth/AuthTabPanel';
import Login from '../components/Auth/Login'
import Register from '../components/Auth/Register'
import AuthContext from '../context/AuthContext';
import useHttp from '../hooks/http.hook';
import { useSnackBar } from '../context/SnackBarContext';

const paperStyle = {
    padding: 20,
    height: '60vh',
    width: 320,
    margin: "20px auto"
};

const AuthPage = () => {
    const [tabValue, setTabValue] = useState("login");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const {showMessage} = useSnackBar();

    const auth = useContext(AuthContext);
    const { error, request, clearError } = useHttp();

    const [loginForm, setLoginForm] = useState({
        email: '',
        password: ''
    });

    const [regForm, setRegForm] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    });

    useEffect(() => {
        showMessage(error);
        clearError();
    }, [error, showMessage, clearError]);


    const loginChangeHandler = event => {
        setLoginForm({ ...loginForm, [event.target.name]: event.target.value });
    }

    const loginHandler = async () => {
        try {
            const data = await request('/api/auth/login', 'POST', { ...loginForm });
            if (data.message) {
                showMessage(data.message);
            }
            auth.login(data.userId, data.token, data.isAdmin);
        } catch (e) {
            showMessage(e.message);
         }
    }

    const regChangeHandler = event => {
        setRegForm({ ...regForm, [event.target.name]: event.target.value });
    }

    const registerHandler = async () => {
        try {
            const data = await request('/api/auth/register', 'POST', { ...regForm });
            showMessage(data.message);
        } catch (e) {
            showMessage(e.message);
        }
    }

    return (
        <Grid
            container
            alignItems="center"
            justify="center"
        >

            <Paper align="center" style={paperStyle}>
                <Typography variant="h4" align="center">Pllist</Typography>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab value="login" label="Login" />
                    <Tab value="reg" label="Register" />
                </Tabs>

                <AuthTabPanel tabIndex="login" tabValue={tabValue}>
                    <Login
                        emailFieldName="email"
                        passwordFieldName="password"
                        changeHandler={loginChangeHandler}
                        loginHandler={loginHandler} />
                </AuthTabPanel>
                <AuthTabPanel tabIndex="reg" tabValue={tabValue}>
                    <Register
                        emailFieldName="email"
                        passwordFieldName="password"
                        confirmPasswordFieldName="confirmPassword"
                        changeHandler={regChangeHandler}
                        registerHandler={registerHandler} />
                </AuthTabPanel>
            </Paper>

        </Grid>
    )
}

export default AuthPage;