import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthPage from './pages/AuthPage';
import PlaylistListPage from './pages/PlaylistListPage';
import PlaylistPage from './pages/PlaylistPage';
import SongListPage from './pages/SongListPage';
import SongPage from './pages/SongPage';
import ImportPage from './pages/ImportPage';
import StatsPage from './pages/StatsPage';

const useRoutes = (isAuthenticated, isAdmin) => {

    if (isAuthenticated) {
        return (
            <Switch>
                <Route path="/playlists" exact component={PlaylistListPage} />
                <Route path="/playlists/:id" component={PlaylistPage} />
                <Route path="/songs" exact component={SongListPage} />
                <Route path="/songs/:id" component={SongPage} />
                <Route path="/stats" component={StatsPage} />
                {isAdmin && <Route path="/import" component={ImportPage} />}
                <Redirect to="/playlists" />
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path="/auth" exact component={AuthPage} />
            <Redirect to="/auth" />
        </Switch>
    )
}

export default useRoutes;