import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AuthContext from '../context/AuthContext';
import useHttp from '../hooks/http.hook';
import { Loader } from '../components/auxiliary/Loader';
import CustomDataGrid from '../components/containers/CustomDataGrid';
import { useSnackBar } from '../context/SnackBarContext';
import HtmlTooltip from '../components/containers/HtmlTooltip';
import GridLink from '../components/containers/GridLink';

const PlaylistListPage = () => {
    const { showMessage } = useSnackBar();

    const history = useHistory();

    const { token, isAdmin } = useContext(AuthContext);
    const { request, loading } = useHttp();
    const [playlists, setPlaylists] = useState('');

    const fetchPlaylists = useCallback(async () => {
        try {
            const data = await request('/api/playlist', 'GET', null, {
                Authorization: `Bearer ${token}`
            });

            setPlaylists(data);
        } catch (e) {
            showMessage(e.message);
        }
    }, [token, request]);

    useEffect(() => {
        fetchPlaylists();
    }, [fetchPlaylists]);

    const handleCreateNewClick = async () => {
        history.push('/playlists/new');
    };

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            flex: 30,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <GridLink href={`/playlists/${params.id}`} text={moment(params.value).format('YYYY-MM-DD')} />
            )
        },
        { field: 'venue', headerName: 'Venue', flex: 20, headerAlign: 'center', align: 'center' },
        {
            field: 'songs',
            headerName: 'Songs',
            flex: 20,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <span>{params.value?.length || '0'}</span>
            )
        },
        {
            field: 'comment',
            headerName: 'Comment',
            flex: 30,
            headerAlign: 'center',
            renderCell: (params) => (
                <HtmlTooltip title={params.value || ''} >
                    <span className="table-cell-trucate">{params.formattedValue}</span>
                </HtmlTooltip>
            )
        },
    ];

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            {!loading && <CustomDataGrid
                pageTitle="Playlists"
                pluralName="playlists"
                path="playlists"
                dataSet={playlists}
                columns={columns}
                handleCreateNewClick={handleCreateNewClick}
                readonly={!isAdmin}
            />}
        </div>

    )
}

export default PlaylistListPage;