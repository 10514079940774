import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SongSelectDialog from './SongSelectDialog';
import SortableSongContainer from './SortableSongContainer';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: 0,
        margin: 0
    },
    deleteMenu: {
        margin: 0,
        padding: 0,
        display: 'inline-block',
        float: 'right',
        boxSizing: 'border-box'
    },
    paper: {
        marginTop: 10,
        marginLeft: '5%',
        marginRight: '5%',
        padding: '10px 20px',
        backgroundColor: '#fbfbfb'
    },
    pageTitle: {
        margin: theme.spacing(2)
    },
    changeMark: {
        marginLeft: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    buttonContainer: {
        height: 40,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    playlistSongsContainer: {
        marginTop: 5,
        marginBottom: 5
    }
}));

const PlaylistForm = ({
    playlist,
    playlistChanged,
    setPlaylistChanged,
    playlistSongs,
    setPlaylistSongs,
    readonly,
    changeHandler,
    handlePlaylistSave,
    handlePlaylistDelete
}) => {
    const classes = useStyles();

    const [songDialogOpen, setSongDialogOpen] = useState(false);
    const [selectedSongs, setSelectedSongs] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    if (!playlist.id) {
        return <p>No playlist data...</p>
    }

    const reorderSongs = (songArray) => {
        return songArray.map((song, index) => {
            return { ...song, order: index + 1 }
        });
    }

    const handleSongListEditCLick = () => {
        setSongDialogOpen(true);
    }

    const handleSongRemove = (songId) => {
        const filteredSongs = playlistSongs.filter(x => x.id !== songId);
        setPlaylistSongs(reorderSongs(filteredSongs));
        setPlaylistChanged(true);
    }

    const handleDialogApply = () => {
        const selectedSongsInOrder = reorderSongs(selectedSongs);
        setPlaylistSongs(selectedSongsInOrder);
        setSongDialogOpen(false);
        setPlaylistChanged(true);
    }

    const handleDialogClose = () => {
        setSongDialogOpen(false);
    }


    const handleDeleteMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDeleteMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteButtonClick = () => {
        handleDeleteMenuClose();
        handlePlaylistDelete();
    }

    return (
        <Grid
            container
            justify="center"
            className={classes.gridContainer}
        >
            <Paper elevation={10} className={classes.paper}>
                {!readonly && <IconButton
                    color='primary'
                    variant="contained"
                    className={classes.deleteMenu}
                    onClick={handleDeleteMenuClick}
                ><MoreHorizIcon /></IconButton>}

                {!readonly && <Menu
                    id="delete-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleDeleteMenuClose}
                >
                    <MenuItem onClick={handleDeleteButtonClick}>Delete Playlist</MenuItem>
                </Menu>}

                <Typography variant="h5" className={classes.pageTitle} align="center">
                    <strong>"{playlist.venue || '{Venue}'}"</strong> <small><i>on</i></small> {moment(playlist.date, 'YYYY-MM-DD').format('MM/DD/YYYY') || '{Date}'}
                    {playlistChanged && <Badge badgeContent='*' color="secondary" className={classes.changeMark} ></Badge>}
                </Typography>

                <Divider variant="middle" />

                <div className={classes.playlistFormContainer}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="venue-date-picker-dialog"
                            label="Venue date"
                            format="MM/dd/yyyy"
                            required
                            value={playlist.date}
                            onChange={changeHandler}
                            readOnly={readonly}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        id="venue"
                        label='Venue'
                        placeholder='Please enter venue name'
                        type='text'
                        fullWidth
                        required
                        value={playlist.venue}
                        onChange={changeHandler}
                        className={classes.textField}
                        InputProps={{
                            readOnly: readonly,
                        }}
                    />
                    <TextField
                        id="comment"
                        label='Comment'
                        placeholder='Comment'
                        type='text'
                        fullWidth
                        multiline
                        value={playlist.comment}
                        onChange={changeHandler}
                        className={classes.textField}
                        InputProps={{
                            readOnly: readonly,
                        }}
                    />

                </div>

                <Divider variant="middle" />

                <div className={classes.playlistSongsContainer}>

                    <Box display="flex" flexDirection="row">

                        <Box flexGrow={1}>
                            <Typography variant="h6" align="center">
                                Songs
                            </Typography>
                        </Box>
                        {!readonly && <Box alignItems="right">
                            <IconButton
                                color='primary'
                                variant="contained"

                                onClick={handleSongListEditCLick}
                            ><EditIcon /></IconButton>
                        </Box>}
                    </Box>

                    <SortableSongContainer
                        playlistSongs={playlistSongs}
                        setPlaylistSongs={setPlaylistSongs}
                        setPlaylistChanged={setPlaylistChanged}
                        reorderSongs={reorderSongs}
                        handleSongRemove={handleSongRemove}
                        readonly={readonly}
                    />

                    {!readonly && songDialogOpen && <SongSelectDialog
                        open={songDialogOpen}
                        playlistSongs={playlistSongs}
                        selectedSongs={selectedSongs}
                        setSelectedSongs={setSelectedSongs}
                        handleDialogApply={handleDialogApply}
                        handleDialogClose={handleDialogClose}
                    />}

                </div>


                <Divider variant="middle" />


                {!readonly && playlistChanged && <div className={classes.buttonContainer}>
                    <Button
                        type='submit'
                        color='primary'
                        variant="contained"
                        onClick={handlePlaylistSave}
                    ><SaveIcon />Save</Button>
                </div>}

            </Paper>
        </Grid >
    )
}

export default PlaylistForm;