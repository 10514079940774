import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import useRoutes from './routes';
import useAuth from './hooks/auth.hook';
import AuthContext from './context/AuthContext';
import { Navbar } from './components/Navbar';
import { Loader } from './components/auxiliary/Loader';
import SnackBarMessage from './components/auxiliary/SnackBarMessage';
import { SnackBarProvider } from './context/SnackBarContext';


function App() {
  const { token, userId, login, logout, ready, isAdmin } = useAuth();
  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated, isAdmin);

  if (!ready) {
    return <Loader />
  }

  return (
    <div>
      <SnackBarProvider>
        <AuthContext.Provider value={{ token, login, logout, userId, isAuthenticated, isAdmin }}>
          <BrowserRouter>
            {isAuthenticated && <Navbar />}
            <div className="container">
              {routes}
            </div>
            <SnackBarMessage />
          </BrowserRouter>
        </AuthContext.Provider>
      </SnackBarProvider>
    </div>
  );
}

export default App;
