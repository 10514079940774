import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const ButtonStatus = {
    Idle: 'Idle',
    Loading: 'Loading',
    Loaded: 'Loaded'
};

const ImportLoadingButton = ({ status, handleClick }) => {

    return (
        <div>
            <Button
                color="primary"
                variant="contained"
                disabled={status === ButtonStatus.Loading}
                style={{backgroundColor: (status === ButtonStatus.Loaded ? 'green': '')} }
                onClick={status === ButtonStatus.Loaded ? null : handleClick}
            >   
                {status === ButtonStatus.Idle && <React.Fragment><CloudDownloadIcon />Add</React.Fragment>}
                {status === ButtonStatus.Loading && <CircularProgress size={30} />}
                {status === ButtonStatus.Loaded && <React.Fragment><ThumbUpIcon />Done</React.Fragment>}
                
            </Button>
        </div>
    )
}

export { ImportLoadingButton, ButtonStatus }