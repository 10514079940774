import React, { useRef, useEffect } from 'react';
import * as d3 from "d3";

const margin = {
    top: 10,
    bottom: 60,
    left: 300,
    right: 40
};

const barColor = "#f50057";
const textColor = "#ffffff";

const SongBarChart = ({ width, height, data }) => {
    const d3Container = useRef(null);

    useEffect(() => {
        if (!data || !data.length || !d3Container.current) {
            return;
        }

        const svg = d3.select(d3Container.current)
            .append('svg')
            .attr('id', 'chart')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const chartWidth = width - margin.left - margin.right;
        const chartHeight = height - margin.top - margin.bottom;

        const xAxis = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.count)])
            .range([0, chartWidth]);

        const xScale = d3.axisBottom()
            .ticks(5)
            .scale(xAxis);

        svg.append("g")
            .attr("transform", "translate(0," + chartHeight + ")")
            .call(xScale)
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "end");

        const yAxis = d3.scaleBand()
            .range([0, chartHeight])
            .domain(data.map(function (d) { return d.title; }))
            .padding(.1);

        svg.append("g")
            .call(d3.axisLeft(yAxis))
            .selectAll("text")
            .attr("font-weight", "bold");

        svg.selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", xAxis(0))
            .attr("y", (d) => yAxis(d.title))
            .attr("width", (d) => xAxis(d.count))
            .attr("height", yAxis.bandwidth())
            .attr("fill", barColor);

        svg
            .append('g')
            .selectAll('text')
            .data(data)
            .enter()
            .append('text')
            .text((d) => d.count)
            .attr("x", (d, i) => xAxis(d.count / 2))
            .attr("y", (d, i) => yAxis(d.title) + (yAxis.bandwidth() / 2) + 5)
            .attr("fill", textColor);

        svg.append("text")
            .attr("x", (chartWidth / 2))
            .attr("y", (height - margin.bottom / 2))
            .attr("text-anchor", "middle")
            .text("Times played");

        svg.selectAll("rect")
            .on('mouseenter', function () {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr('opacity', 0.6);
            })
            .on('mouseleave', function () {
                d3.select(this)
                    .transition()
                    .duration(100)
                    .attr('opacity', 1);
            });

    }, [data, width, height]);

    return (
        <svg
            className="d3-component"
            width={width}
            height={height}
            ref={d3Container}
        />
    );
}

export default SongBarChart;