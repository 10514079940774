import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { useSnackBar } from '../../context/SnackBarContext';

const SnackBarMessage = () => {
    const { message, clearMessage } = useSnackBar();

    const transition = (props) => {
        return (
            <Slide {...props} direction="up" />
        );
    }

    return (
        <React.Fragment>
            {message && <Snackbar
                open={!!message}
                onClose={clearMessage}
                message={message}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={transition}
                key="snackbar-message"
                style={{ paddingTop: 70 }}
            />}
        </React.Fragment>
    )
}

export default SnackBarMessage;