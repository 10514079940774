import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../components/auxiliary/Loader';
import AuthContext from '../context/AuthContext';
import useHttp from '../hooks/http.hook';
import { useSnackBar } from '../context/SnackBarContext';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import { ButtonStatus, ImportLoadingButton } from '../components/containers/ImportLoadingButton';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: 0,
        margin: 0
    },
    paper: {
        marginTop: 10,
        marginLeft: '5%',
        marginRight: '5%',
        padding: '10px 20px',
        backgroundColor: '#fbfbfb',
        width: '725px'
    },
    pageTitle: {
        margin: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}));

const ImportPage = () => {
    const classes = useStyles();

    const { showMessage } = useSnackBar();

    const { token } = useContext(AuthContext);
    const { request, loading } = useHttp();
    const [searchText, setSearchText] = useState('');
    const [searchResultText, setSearchResultText] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const getResults = async () => {
        if (!searchText) {
            return;
        }

        try {
            const data = await request(`/api/lastfm/search`, 'POST', { searchText }, {
                Authorization: `Bearer ${token}`
            });

            setSearchResults(data);
            setSearchResultText(`Search for "${searchText}". Found: ${data.length || '0'}`);
        } catch (e) {
            showMessage(e.message);
        }
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    }

    const onSearchTextKeyDown = (event) => {
        if (event.key === 'Enter') {
            getResults();
        }
    }

    const handleSongImport = async (songData) => {
        if (!songData) {
            return;
        }
        
        const trackId = songData.id;

        try {
            setSearchResults(r => r.map(track => {
                return track.id === trackId
                    ? { ...track, status: ButtonStatus.Loading }
                    : { ...track };
            }));

            const response = await request(`/api/lastfm/add`, 'POST', songData, {
                Authorization: `Bearer ${token}`
            }, true);

            setSearchResults(r => r.map(track => {
                return track.id === trackId
                    ? { ...track, status: ButtonStatus.Loaded }
                    : { ...track };
            }));

            if (response.message) {
                showMessage(response.message);
            }

        } catch (e) {
            showMessage(e.message);
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <Grid
            container
            justify="center"
            className={classes.gridContainer}
        >
            <Paper elevation={10} className={classes.paper}>
                <Typography variant="h5" className={classes.pageTitle} align="center">
                    Import from Last FM
                </Typography>

                <Box display="flex" flexDirection="row">
                    <TextField
                        id="search-text"
                        label="Search text"
                        variant="outlined"
                        value={searchText}
                        fullWidth
                        onChange={handleSearchTextChange}
                        onKeyDown={onSearchTextKeyDown}
                    />
                    <IconButton
                        type="submit"
                        color='primary'
                        variant="contained"
                        onClick={getResults}
                    ><SearchIcon /></IconButton>
                </Box>
                {searchResultText && <span><i>{searchResultText}</i></span>}

                <Typography variant="h6" className={classes.pageTitle} align="center">

                </Typography>

                <Divider variant="middle" />
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><strong>Name</strong></TableCell>
                                <TableCell align="center"><strong>Artist Name</strong></TableCell>
                                <TableCell width="30" align="center"><strong>Import</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.artistName}
                                    </TableCell>
                                    <TableCell width="30" align="center">
                                        <ImportLoadingButton
                                            status={row.status || ButtonStatus.Idle}
                                            handleClick={() => handleSongImport(row)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid >
    )
}

export default ImportPage;