import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DataGrid } from "@material-ui/data-grid";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: 20,
        height: '75vh',
        width: 'inherit',
        margin: '10px auto',
        backgroundColor: '#fbfbfb'
    },
    titleContainer: {
        marginBottom: 20,
        margin: '0 50px',
        height: 40
    },
    title: {
        fontWeight: 'bold',
        display: 'inline-block',
        height: 'inherit',
        boxSizing: 'border-box'
    },
    addButton: {
        display: 'inline-block',
        float: 'right',
        boxSizing: 'border-box',
        height: 'inherit'
    },
    btnTitle: {
        display: 'inline-block',
        msTransform: 'translateY(-25%)',
        transform: 'translateY(-25%)'
    },
    grid: {
        padding: 0,
        height: 'inherit',
        width: 'inherit',
        margin: 'ingerit',
        border: '',

        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
        },
        '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#e2e2e2',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '1px solid #cccbcb',
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: '2px solid #cccbcb',
        },
        '& .MuiDataGrid-cell': {
            color: 'rgba(0,0,0,.85)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        }
    }
}));


const CustomDataGrid = ({
    pageTitle,
    pluralName,
    dataSet,
    columns,
    handleCreateNewClick,
    readonly
}) => {
    const classes = useStyles();

    let rows = [];

    if (dataSet && dataSet.length) {
        rows = dataSet.map(data => {
            return {
                ...data,
                id: data._id
            }
        });
    }


    return (
        <Paper align="center" className={classes.paper}>
            <div className={classes.titleContainer}>
                <Typography variant="h5" className={classes.title}>
                    {pageTitle}
                </Typography>
                {!readonly && <Button
                    color='primary'
                    variant="contained"
                    className={classes.addButton}
                    onClick={handleCreateNewClick}
                ><AddIcon /><span className={classes.btnTitle}>New</span></Button>}
            </div>

            {!dataSet.length &&
                <Typography variant="h6" align="left">
                    No {pluralName} here yet...
                </Typography>
            }

            {dataSet && <DataGrid
                columns={columns}
                rows={rows}
                autoPageSize
                rowsPerPageOptions={[10, 25, 50, 100]}
                className={classes.grid}
            />}
        </Paper>
    )
};


export default CustomDataGrid;