import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import GridLink from '../containers/GridLink';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: 0,
        margin: 0
    },
    paper: {
        marginTop: 10,
        marginLeft: '5%',
        marginRight: '5%',
        padding: '10px 20px',
        backgroundColor: '#fbfbfb'
    },
    pageTitle: {
        margin: theme.spacing(2)
    },
    changeMark: {
        color: 'red',
        fontWeight: 'bold',
        marginLeft: 15
    },
    songFormContainer: {
        marginTop: 5,
        marginBottom: 5
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    buttonContainer: {
        height: 40,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    fileInput: {
        display: 'none'
    },
    songFilesContainer: {
        marginTop: 5,
        marginBottom: 5
    }
}));

const SongForm = ({
    song,
    songFiles,
    isSongNew,
    songChanged,
    readonly,
    changeHandler,
    handleSongSave,
    handleFileUpload,
    handleFileDelete
}) => {
    const classes = useStyles();

    if (!song.id) {
        return <p>No song data...</p>
    }

    return (
        <Grid
            container
            justify="center"
            className={classes.gridContainer}
        >
            <Paper elevation={10} className={classes.paper}>

                <Typography variant="h5" className={classes.pageTitle} align="center">
                    <strong>"{song.title || '{Song title}'}"</strong> <small><i>by</i></small> {song.performer || '{Performer/Author}'}
                    {songChanged && <Badge badgeContent='*' color="secondary" className={classes.changeMark} ></Badge>}
                </Typography>

                <Divider variant="middle" />

                <div className={classes.songFormContainer}>
                    <TextField
                        id='title'
                        label='Title'
                        placeholder='Please enter song title'
                        type='text'
                        fullWidth
                        required
                        value={song.title}
                        onChange={changeHandler}
                        className={classes.textField}
                        InputProps={{
                            readOnly: readonly
                        }}
                    />
                    <TextField
                        id="performer"
                        label='Performer/Author'
                        placeholder='Please enter Performer/Author'
                        type='text'
                        fullWidth
                        value={song.performer}
                        onChange={changeHandler}
                        className={classes.textField}
                        InputProps={{
                            readOnly: readonly
                        }}
                    />
                    <TextField
                        id="keys"
                        label='Key(s)'
                        placeholder='Please enter key(s) of the song'
                        type='text'
                        fullWidth
                        value={song.keys}
                        onChange={changeHandler}
                        className={classes.textField}
                        InputProps={{
                            readOnly: readonly
                        }}
                    />
                    <TextField
                        id="comment"
                        label='Comment'
                        placeholder='Comment'
                        type='text'
                        fullWidth
                        multiline
                        value={song.comment}
                        onChange={changeHandler}
                        className={classes.textField}
                        InputProps={{
                            readOnly: readonly
                        }}
                    />
                    {!readonly && songChanged && <div className={classes.buttonContainer}>
                        <Button
                            type='submit'
                            color='primary'
                            variant="contained"
                            onClick={handleSongSave}
                        ><SaveIcon />Save</Button>
                    </div>}

                </div>

                <Divider variant="middle" />

                <div className={classes.songFilesContainer} hidden={isSongNew}>

                    <Box display="flex" flexDirection="row">

                        <Box flexGrow={1}>
                            <Typography variant="h6" align="center">
                                Song files
                            </Typography>
                        </Box>
                        {!readonly && <Box alignItems="right">
                            <IconButton
                                color='primary'
                                variant="contained"
                                component="label"
                            >
                                <AddCircleOutlineOutlinedIcon />
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleFileUpload}
                                />
                            </IconButton>
                        </Box>}
                    </Box>

                    {(!songFiles || !songFiles.length) &&
                        <Box textAlign="center" m={1}>
                            No song files yet...
                        </Box>
                    }

                    {songFiles && songFiles.length > 0 && <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"><strong>File name</strong></TableCell>
                                    {!readonly && <TableCell width="30" align="center"><strong>Delete</strong></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {songFiles.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="left">
                                            <GridLink href={`${row.webViewLink}`} text={row.name} openNewTab={true} />
                                        </TableCell>
                                        {!readonly && <TableCell width="30" align="center">
                                            <IconButton
                                                color='secondary'
                                                size='small'
                                                onClick={() => handleFileDelete(row.id)}
                                            ><DeleteIcon /></IconButton>
                                        </TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}


                </div>

            </Paper>
        </Grid >
    )
}

export default SongForm;