import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import useHttp from '../hooks/http.hook';
import { Loader } from '../components/auxiliary/Loader';
import CustomDataGrid from '../components/containers/CustomDataGrid';
import { useSnackBar } from '../context/SnackBarContext';
import HtmlTooltip from '../components/containers/HtmlTooltip';
import GridLink from '../components/containers/GridLink';

const SongListPage = () => {
    const { showMessage } = useSnackBar();

    const history = useHistory();

    const { token, isAdmin } = useContext(AuthContext);
    const { request, loading } = useHttp();
    const [songs, setSongs] = useState('');

    const fetchSongs = useCallback(async () => {
        try {
            const query = `query {
                getAllSongs {
                  _id, title, performer, keys, comment
                }
              }`;

            const data = await request('/graphql', 'POST', { query }, {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json'
            });

            if (data.errors) {
                showMessage(data.errors[0].message);
                return;
            }

            const songsData = data.data.getAllSongs.map(s => { return { ...s, id: s._id}});

            setSongs(songsData);
        } catch (e) {
            showMessage(e.message);
        }
    }, [token, request]);

    useEffect(() => {
        fetchSongs();
    }, [fetchSongs]);

    const handleCreateNewClick = async () => {
        history.push('/songs/new');
    };


    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            flex: 30,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <GridLink href={`/songs/${params.id}`} text={params.formattedValue} />
            )
        },
        { field: 'performer', headerName: 'Performer/Author', flex: 20, headerAlign: 'center', align: 'center' },
        { field: 'keys', headerName: 'Key(s)', flex: 20, headerAlign: 'center', align: 'center' },
        {
            field: 'comment',
            headerName: 'Comment',
            flex: 30,
            headerAlign: 'center',
            renderCell: (params) => (
                <HtmlTooltip title={params.value} >
                    <span className="table-cell-trucate">{params.formattedValue}</span>
                </HtmlTooltip>
            )
        },
    ];

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            {!loading && <CustomDataGrid
                pageTitle="Songs"
                pluralName="songs"
                path="songs"
                dataSet={songs}
                columns={columns}
                handleCreateNewClick={handleCreateNewClick}
                readonly={!isAdmin}
            />}
        </div>

    )
}

export default SongListPage;