import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Loader } from '../auxiliary/Loader';
import AuthContext from '../../context/AuthContext';
import useHttp from '../../hooks/http.hook';
import { useSnackBar } from '../../context/SnackBarContext';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    chipsPaper: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    noSongsSelectedMessage: {
        textAlign: 'center',
        fontStyle: 'italic'
    }
}));

const SongSelectDialog = ({ open, playlistSongs, selectedSongs, setSelectedSongs, handleDialogApply, handleDialogClose }) => {
    const classes = useStyles();

    const { showMessage } = useSnackBar();

    const { token } = useContext(AuthContext);
    const { request, loading } = useHttp();

    const [availableSongs, setAvailableSongs] = useState([]);

    const getSongs = useCallback(async () => {
        try {
            const data = await request(`/api/song`, 'GET', null, {
                Authorization: `Bearer ${token}`
            });

            if (!data.length && !data.length) {
                showMessage('No songs retrieved');
                handleDialogClose();
                return;
            }

            const allSongs = data.map((song) => { return { ...song, id: song._id } });

            setSelectedSongs(playlistSongs);
            setAvailableSongs(allSongs);
        } catch (e) {
            showMessage(e.message);
        }
    }, [playlistSongs]);



    useEffect(() => {
        if (!open) {
            return;
        }

        getSongs();
    }, [getSongs, open]);


    const handleDelete = (chipToDelete) => () => {
        setSelectedSongs((songChips) => songChips.filter((songChip) => songChip.id !== chipToDelete.id));
    };

    const isSongChecked = (songId) => {
        const index = selectedSongs.findIndex(x => x.id === songId);
        return (index !== -1);
    }

    const handleSelectChange = (song) => () => {
        var index = selectedSongs.findIndex(x => x.id === song.id);
        if (index === -1) {
            setSelectedSongs([...selectedSongs, song]);
        } else {
            setSelectedSongs(selectedSongs.filter((x) => x.id !== song.id));
        };
    }

    const renderDialogInnerContent = () => {
        if (loading) {
            return (
                <React.Fragment>
                    <Loader />
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <DialogContent>
                    <DialogContentText>
                        Selected songs:
                    </DialogContentText>

                    {(selectedSongs && selectedSongs.length)
                        ? <Paper component="ul" className={classes.chipsPaper}>
                            {selectedSongs.map((song) => {
                                return (
                                    <li key={song.id}>
                                        <Chip
                                            label={`${song.title} (${song.performer || 'Unknown'})`}
                                            onDelete={handleDelete(song)}
                                            className={classes.chip}
                                        />
                                    </li>
                                );
                            })}
                        </Paper>
                        : <p className={classes.noSongsSelectedMessage}>No songs selected</p>}

                    <Paper>
                        {availableSongs.map((song) => (
                            <MenuItem
                                key={song.id}
                                value={song.id}
                                onClick={handleSelectChange(song)}
                            >
                                <Checkbox checked={isSongChecked(song.id)} />
                                <ListItemText primary={`${song.title} (${song.performer || ''})`} />
                            </MenuItem>
                        ))}
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogApply} color="primary">
                        Apply
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    return (
        <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
            <DialogTitle id="song-dialog-title">Songs select</DialogTitle>
            {renderDialogInnerContent()}
        </Dialog>
    )
}

export default SongSelectDialog;