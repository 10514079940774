import React, { useEffect, useState, useContext, useCallback } from 'react';
import useHttp from '../hooks/http.hook';
import AuthContext from '../context/AuthContext';
import { useSnackBar } from '../context/SnackBarContext';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../components/auxiliary/Loader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SongBarChart from '../components/charts/SongBarChart';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: 10,
        marginLeft: '5%',
        marginRight: '5%',
        padding: '10px 20px',
        backgroundColor: '#fbfbfb',
    },
    pageTitle: {
        margin: theme.spacing(2)
    }
}));

const width = 960;
const height = 480;

const StatsPage = () => {
    const classes = useStyles();

    const { showMessage } = useSnackBar();

    const { token } = useContext(AuthContext);
    const { request, loading } = useHttp();

    const [stats, setStats] = useState([]);

    const getSongsStats = useCallback(async () => {
        try {
            const data = await request(`/api/stats`, 'GET', null, {
                Authorization: `Bearer ${token}`
            });

            setStats(data);
        } catch (e) {
            showMessage(e.message);
        }
    }, [token, request]);

    useEffect(() => {
        getSongsStats();
    }, [getSongsStats]);

    if (loading) {
        return <Loader />;
    }

    return (
        <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.pageTitle} align="center">
                Statistics
            </Typography>

            {!stats.length
                ? <Typography variant="h6" align="center">
                    No songs played yet...
                </Typography>
                : <SongBarChart
                    width={width}
                    height={height}
                    data={stats}
                />}
        </Paper>
    )
}

export default StatsPage;