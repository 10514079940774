import { useState, useCallback, useEffect } from "react";

const storageName = 'userData';

const useAuth = () => {
    const [token, setToken] = useState(null);
    const [ready, setReady] = useState(false);
    const [userId, setUserId] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const login = useCallback((id, jwtToken, isUserAdmin = false) => {
        setUserId(id);
        setToken(jwtToken);
        setIsAdmin(isUserAdmin);

        localStorage.setItem(storageName, JSON.stringify({
            userId: id, token: jwtToken, isAdmin: isUserAdmin
        }));
    }, []);

    const logout = useCallback(() => {
        setUserId(null);
        setToken(null);
        setIsAdmin(false);

        localStorage.removeItem(storageName);
    }, []);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName));

        if (data && data.token) {
            login(data.userId, data.token, data.isAdmin);
        }
        setReady(true);
    }, [login]);

    return { login, logout, userId, token, ready, isAdmin };
}

export default useAuth;