import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Loader } from '../components/auxiliary/Loader';
import AuthContext from '../context/AuthContext';
import useHttp from '../hooks/http.hook';
import PlaylistForm from '../components/Playlist/PlaylistForm';
import { useSnackBar } from '../context/SnackBarContext';

const PlaylistPage = () => {
    const { showMessage } = useSnackBar();

    const history = useHistory();

    const { token, isAdmin } = useContext(AuthContext);
    const { request, loading } = useHttp();
    const playlistId = useParams().id;
    const [playlist, setPlaylist] = useState({
        id: '',
        date: new Date(),
        venue: '',
        songs: []
    });
    const [playlistSongs, setPlaylistSongs] = useState([]);
    const [playlistChanged, setPlaylistChanged] = useState(false);

    const getPlaylist = useCallback(async () => {
        if (playlistId === 'new') {
            setPlaylist({ ...playlist, id: playlistId });
            return;
        }

        try {
            const data = await request(`/api/playlist/${playlistId}`, 'GET', null, {
                Authorization: `Bearer ${token}`
            });

            setPlaylist({ ...data, id: data._id, date: moment(data.date).format('YYYY/MM/DD') });
            setPlaylistSongs(data.songs || []);

        } catch (e) {
            showMessage(e.message);
        }
    }, [token, playlistId, request]);

    useEffect(() => {
        getPlaylist();
    }, [getPlaylist]);

    if (loading) {
        return <Loader />
    }

    const changeHandler = (eventData) => {
        if (typeof eventData.getMonth === 'function') {
            setPlaylist({ ...playlist, date: eventData });
        } else {
            setPlaylist({ ...playlist, [eventData.target.id]: eventData.target.value });
        }
        setPlaylistChanged(true);
    }

    const handlePlaylistSave = async () => {
        const playlistToSave = { ...playlist, playlistSongs: playlistSongs };

        try {
            const savedData = await request(`/api/playlist`, 'POST', playlistToSave, {
                Authorization: `Bearer ${token}`
            });
            setPlaylistChanged(false);

            if (playlistId === "new") {
                history.push(`/playlists/${savedData.playlist._id}`)
            }
        } catch (e) {
            showMessage(e.message);
        }
    };

    const handlePlaylistDelete = async () => {
        if (!playlistId || playlistId === "new") {
            alert("Playlist hasn't been saved yet");
            return;
        }

        let confirmDeletion = window.confirm("Are you sure you want to delete this playlist?");

        if (!confirmDeletion) {
            return;
        }

        try {
            const deletionResult = await request(`/api/playlist/${playlistId}`, 'DELETE', null, {
                Authorization: `Bearer ${token}`
            });

            setPlaylistChanged(false);

            if (deletionResult && deletionResult.message) {
                showMessage(deletionResult.message);
            }
            
            history.push('/playlists');
        } catch (e) {
            showMessage(e.message);
        }
    }

    return (
        <div>
            {!loading && playlist && <PlaylistForm
                playlist={playlist}
                playlistChanged={playlistChanged}
                setPlaylistChanged={setPlaylistChanged}
                playlistSongs={playlistSongs}
                setPlaylistSongs={setPlaylistSongs}
                readonly={!isAdmin}
                canBeEdited={true} //TODO
                changeHandler={changeHandler}
                handlePlaylistSave={handlePlaylistSave}
                handlePlaylistDelete={handlePlaylistDelete}
            />}
        </div>
    );
}

export default PlaylistPage;