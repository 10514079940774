import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MusicNoteTwoToneIcon from '@material-ui/icons/MusicNoteTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import AirplayIcon from '@material-ui/icons/Airplay';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navButton: {
        color: 'inherit',
        margin: theme.spacing(0, 2)
    },
    logoutButton: {
        color: 'red'
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));

export const Navbar = () => {
    const classes = useStyles();

    const history = useHistory()
    const { logout, isAdmin } = useContext(AuthContext);

    const navigateHandler = linkValue => {
        history.push(linkValue);
    }

    const logoutHandler = () => {
        logout();
        history.push('/');
    }

    const [drawerState, setDrawerState] = useState(false);

    const toggleDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerState(isOpen);
    };

    return (
        <div>
            <Drawer anchor="left" open={drawerState} onClose={toggleDrawer(false)}>
                <div
                    className={classes.list}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem button key="drawer-pllist-button" onClick={() => navigateHandler('/playlists')}>
                            <ListItemIcon><AirplayIcon /></ListItemIcon>
                            <ListItemText primary="Pllist" />
                        </ListItem>
                        <ListItem button key="drawer-statistics-button" onClick={() => navigateHandler('/stats')}>
                            <ListItemIcon><EqualizerIcon /></ListItemIcon>
                            <ListItemText primary="Statistics" />
                        </ListItem>
                    </List>
                    <Divider />
                    {isAdmin && <React.Fragment>
                        <ListItem button key="drawer-pllist-button" onClick={() => navigateHandler('/import')}>
                            <ListItemIcon><ImportExportIcon /></ListItemIcon>
                            <ListItemText primary="Import" />
                        </ListItem>
                        <Divider />
                    </React.Fragment>}
                    <List>
                        <ListItem button key="drawer-logout-button" className={classes.logoutButton} onClick={logoutHandler}>
                            <ListItemIcon><ExitToAppTwoToneIcon /></ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </div>
            </Drawer>

            <AppBar position="static" className={classes.root}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" className={classes.menuButton} onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" className={classes.title}>
                        Pllist
                    </Typography>

                    <Button className={classes.navButton} onClick={() => navigateHandler('/playlists')}>
                        <LibraryBooksIcon />Playlists
                    </Button>

                    <Button className={classes.navButton} onClick={() => navigateHandler('/songs')}>
                        <MusicNoteTwoToneIcon />Songs
                    </Button>

                </Toolbar>
            </AppBar>

        </div>
    )
}