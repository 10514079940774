import React, { useCallback } from 'react';
import arrayMove from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import GridLink from '../containers/GridLink';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/Delete';

const SortableSongContainer = ({
    playlistSongs,
    setPlaylistSongs,
    setPlaylistChanged,
    reorderSongs,
    handleSongRemove,
    readonly
}) => {
    const DragHandle = sortableHandle(() => <DragIndicatorIcon />);

    const SortableItem = sortableElement(({ value }) => (
        <TableRow key={value.id}>
            {!readonly && <TableCell width="20" align="left">
                <DragHandle />
            </TableCell>}
            <TableCell align="left">
                {`${value.order}. ` || ''}<GridLink href={`/songs/${value.id}`} text={value.title} />  <small><i>by</i></small> {value.performer || 'Unknown'}
            </TableCell>
            {!readonly && <TableCell width="30" align="center">
                <IconButton
                    color='secondary'
                    size='small'
                onClick={() => handleSongRemove(value.id)}
                ><DeleteIcon /></IconButton>
            </TableCell>}
        </TableRow>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
        return (
            <Table size="small">
                {children}
            </Table>
        );
    });

    const moveSong = useCallback(({ oldIndex, newIndex }) => {
        setPlaylistSongs(songs => {
            const movedSongs = arrayMove(songs, oldIndex, newIndex);
            return reorderSongs(movedSongs);
        });
        setPlaylistChanged(true);
    }, [playlistSongs]);

    return (
        <Paper>
            <Table size="small">
                <TableBody>
                    <SortableContainer onSortEnd={moveSong} useDragHandle>
                        {playlistSongs.map((song, index) => (
                            <SortableItem key={`song-${song.id}`} index={index} value={song} />
                        ))}
                    </SortableContainer>
                </TableBody>
            </Table>
        </Paper>
    )
}

export default SortableSongContainer;