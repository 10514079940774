import { createContext } from "react";

function blankFunction(){};

const AuthContext = createContext({
    token: null,
    userId: null,
    isAdmin: false,
    login: blankFunction,
    logout: blankFunction,
    isAuthenticated: false
});

export default AuthContext;