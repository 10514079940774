import React, { useContext, useState } from "react";

const SnackBarContext = React.createContext();

export const useSnackBar = () => {
    return useContext(SnackBarContext);
}

export const SnackBarProvider = ({ children }) => {
    const [message, setMessage] = useState(null);

    const showMessage = (msg) => {
        if (!msg) {
            return;
        }
        setMessage(msg);
    };

    const clearMessage = () => {
        setMessage(null);
    }

    return (
        <SnackBarContext.Provider value={{
            message,
            showMessage,
            clearMessage
        }}>
            {children}
        </SnackBarContext.Provider>
    )
}