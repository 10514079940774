import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Loader } from '../components/auxiliary/Loader';
import AuthContext from '../context/AuthContext';
import useHttp from '../hooks/http.hook';
import SongForm from '../components/Song/SongForm';
import { useSnackBar } from '../context/SnackBarContext';

const SongPage = () => {
    const { showMessage } = useSnackBar();

    const history = useHistory();

    const { token, isAdmin } = useContext(AuthContext);
    const { request, uploadRequest, loading } = useHttp();
    const songId = useParams().id;
    const [song, setSong] = useState({
        id: '',
        title: '',
        performer: '',
        keys: '',
        comment: ''
    });
    const [songFiles, setSongFiles] = useState([]);
    const [songChanged, setSongChanged] = useState(false);

    const getSong = useCallback(async () => {
        if (songId === 'new') {
            setSong({ ...song, id: songId });
            return;
        }

        try {
            const query = `query {
                getSongById(id: "${songId}") {
                    _id, title, performer, keys, comment
                }
            }`;

            const data = await request(`/graphql`, 'POST', { query }, {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json'
            });

            if (data.errors) {
                showMessage(data.errors[0].message);
                return;
            }

            const songData = data.data.getSongById;

            setSong({ ...songData, id: songData._id });
            setSongChanged(false);

        } catch (e) {
            showMessage(e.message);
        }
    }, [token, songId, request]);

    const getSongFiles = async () => {
        if (songId === 'new') {
            return;
        }

        try {
            const data = await request(`/api/drive/${songId}`, 'GET', null, {
                Authorization: `Bearer ${token}`
            });

            setSongFiles(data);
        } catch (e) {
            showMessage(e.message);
        }
    };

    useEffect(() => {
        getSong();
        getSongFiles();
    }, [getSong]);

    if (loading) {
        return <Loader />
    }

    const changeHandler = (event) => {
        setSong({ ...song, [event.target.id]: event.target.value });
        setSongChanged(true);
    }

    const handleSongSave = async () => {
        try {
            const query = `
                mutation {
                    addOrUpdateSong(song: {
                        id: "${songId}"
                        title: "${song.title}"
                        performer: "${song.performer}"
                        keys: "${song.keys}"
                        comment: "${song.comment.replace(/\n/g, "\\n")}"
                    }) { _id }
                }
            `;

            const savedData = await request(`/graphql`, 'POST', { query }, {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json'
            });

            if (savedData.errors) {
                showMessage(savedData.errors[0].message);
                return;
            }

            setSongChanged(false);

            if (songId === "new") {
                history.push(`/songs/${savedData.data.addOrUpdateSong._id}`)
            }
        } catch (e) {
            showMessage(e.message);
        }
    };

    const handleFileUpload = async (event) => {
        if (songId === "new") {
            showMessage("You need to save song data first");
        }

        try {
            let formData = new FormData();
            formData.append("file", event.target.files[0]);
            formData.append("songId", songId);
            const uploadedFile = await uploadRequest(`/api/drive`, formData, {
                Authorization: `Bearer ${token}`
            });

            setSongFiles(files => !files ? [uploadedFile] : [...files, uploadedFile]);
        } catch (e) {
            showMessage(e.message);
        }
    }

    const handleFileDelete = async (fileId) => {
        if (songId === "new") {
            showMessage("You need to save song data first");
        }

        let confirmDeletion = window.confirm("Are you sure you want to delete this file?");

        if (!confirmDeletion) {
            return;
        }

        try {
            await request(`/api/drive/${fileId}`, 'DELETE', null, {
                Authorization: `Bearer ${token}`
            });

            setSongFiles(files => files.filter(x => x.id !== fileId));
        } catch (e) {
            showMessage(e.message);
        }
    }

    return (
        <div>
            {!loading && song && <SongForm
                song={song}
                songFiles={songFiles}
                songChanged={songChanged}
                isSongNew={songId === "new"}
                readonly={!isAdmin}
                changeHandler={changeHandler}
                handleSongSave={handleSongSave}
                handleFileUpload={handleFileUpload}
                handleFileDelete={handleFileDelete}
            />}
        </div>
    );
}

export default SongPage;