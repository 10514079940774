import React from 'react';
import Link from '@material-ui/core/Link';

const gridLinkStyle = {
    textDecoration: 'underline'
}

const GridLink = ({ href, text, openNewTab = false }) => (
    <Link
        href={href}
        color="inherit"
        target={openNewTab ? "_blank" : ''}
        style={gridLinkStyle}
    >
        {text}
    </Link>
)

export default GridLink;